import React from 'react';
import jsPDF from 'jspdf';
import bank1 from '../../../assets/images/Bank1.png';
import bank2 from '../../../assets/images/Bank2.png';
import bank3 from '../../../assets/images/Bank3.png';
import logo from '../../../assets/images/logo.png';

interface Attachment {
  data: string;
  name: string;
}

interface Supplier {
  id: number;
  createdAt: string;
  updatedAt: string;
  supplierName: string;
  supplierVatNumber: string;
  supplierBankDetails: string;
  supplierNationalAddress: string;
}

interface MyData {
  id: number;
  invoiceNumber: string;
  amount: number;
  priority: string;
  status: string;
  typeOfExpense: string;
  expenseType: string;
  lineOfBusiness: string;
  departmentName: string;
  serialNumber: string; // Add serialNumber field
  businessUnit: string;
  currency: string;
  userId: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  bank: string;
  Supplier: Supplier;
  attachments: Attachment[];
}

const DownloadPDF: React.FC<{ data: MyData }> = ({ data }) => {
  const handleDownload = () => {
    const doc = new jsPDF();
    const logoImage = new Image();
    logoImage.src = logo;
    logoImage.onload = () => {
      doc.addImage(logoImage, 'PNG', 80, 2, 50, 25);

      //Invoice Number
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.setTextColor(0, 0, 0);
      doc.text(`Invoice Number: ${data.invoiceNumber}`, 40, 43, { align: 'center' });

      //Description
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(0, 0, 0);
      const descriptionLines = doc.splitTextToSize(data.description, 210);
      doc.text(descriptionLines, 5, 63);

      //1st List
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(0, 0, 0);
      doc.text(`1.     Transactions - View Payments - View Approval Quene`, 5, 93);

      //2nd List
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.setTextColor(0, 0, 0);
      doc.text(`2.     Approve`, 5, 103);

      const img = new Image();
      if (data.bank === "ANB Bank" || data.bank === "ANB HR") {
        img.src = bank1;
      } else if (data.bank === "GIB Customs" || data.bank === "GIB Main" || data.bank === "GIB UAE" || data.bank === "GIB Bahrain" || data.bank === "GIB USD") {
        img.src = bank2;
      } else if (data.bank === "BSF") {
        img.src = bank3;
      }
      img.onload = () => {
        doc.addImage(img, 'PNG', 1, 125, 205, 70);
        // Save the PDF
        doc.save(`${data.invoiceNumber}.pdf`);
      };
    };
  };

  return (
    <button type='button' className='btn btn-primary' onClick={handleDownload}>
      <i className="ri-download-2-line align-bottom me-1"></i> Download PDF
    </button>
  );
};

export default DownloadPDF;
